<template>
    <div>
        <Header headerType="advance_result"/> 


        <div :class="'container-fluid' + ($route.query.zoom ? ' fullscreen' : '') + (isUserFrench ? ' page-french' : '')">
            <div class="row">
                <div class="main-box-fixed">                        
                    <div class="box-fixed" >
                        <div class="title">
                            <div v-if="isFilter" class="count_filter">
                                {{ count_filter + "/" + count }}
                            </div>
                            <h1 v-else>
                                {{ count }}
                            </h1>
                            <h2>{{$t('Result')}}</h2>
                        </div>
                    </div>
                    <div class="advance box-fix-left" ref="menuFlag" style="top:0px;">
                        <ul>
                            <label class="flag-active">  
                                                             
                                <li class="active pointerNone" >
                                    <img :src="require('@/assets/images/flag/new/fr.svg')" width="35" height="35" style="margin-bottom: 5px;" />
                                    <p> 
                                        <span style="margin-right: 0;"></span>
                                        AP/AC
                                    </p>
                                    <p class="short_name">(FRA)</p> 
                                </li>                                
                            </label>

                             

                        </ul>
                    </div>
                </div>

                <div class="main-body">
                    <div class="body-fix">
                        <div class="body-custom">
                            <div class="box-btn-menu">  
                                <a href="javascript:void(0)" class="btn btn-border-orange btn-muted pointerNone">{{$t('Overview')}}</a>
                                <a href="javascript:void(0)" @click="selectTab('expert')" :class="'btn ' + (!$route.query.tab || $route.query.tab == 'expert' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Expert')}}</a>
                                <a href="javascript:void(0)" class="btn btn-border-orange btn-muted pointerNone">{{$t('Clinical trials')}}</a>
                                <a href="javascript:void(0)" class="btn btn-border-orange btn-muted pointerNone">{{$t('Prix')}}</a>
                            </div> 
                        </div>
                    </div>

                    <div v-if="(!$route.query.tab || $route.query.tab == 'expert')">
                    <div class="body-w100" v-if="data.data && data.data.length > 0">
                        <div class="box-detail">
                            <ConfigColumnAdvance :type="type" :column="column" :reset_column="reset_column" />
                            <ModalSaveKeyword :type="type" />   
                            <IconTopTable @onRequest="request_data" :page="page" @onSelectExport="selectExport" @onClearExport="clearExport"/>
                            <div class="content-table-results mt-2">
                                <table class="table table-fixed table-stripes">
                                    <ColumnColGroup page="atu_postatu" :column="orderColumn" :default_column="default_column" />
                                    <ColumnThead page="atu_postatu" :column="orderColumn" :default_column="default_column" />  
                                    <tbody> 
                                        <template v-for="(item, key) in data.data" >
                                        <tr v-for="(sub_item, sub_key) in max_row(item)" :key="key+'_'+sub_key" :class="odd_even_border(key, sub_key)" >                
                                            
                                            <td>
                                                <template v-if="sub_key===0">
                                                    <label class="table-check">
                                                        <input type="checkbox"  v-model="select_export" :value="item.drug_id">
                                                        <span class="checkbox-table"></span>
                                                    </label>
                                                </template>
                                            </td>
                                            <td>
                                                <template v-if="sub_key===0">
                                                <router-link :to="'/detail/'+item.drug_id+'/atu'" target="_blank" rel="noopener">
                                                    <div class="icon-fiche"></div>
                                                </router-link> 
                                                <div v-if="isAdmin">
                                                    <a :href="modifyPage('atu_france', item.drug_id)" target="_blank" rel="noopener"> 
                                                        <em class="icon-edit"></em>
                                                    </a>
                                                </div>
                                                </template>
                                            </td>
                                            <template v-for="(column) in orderColumn" :key="column.code" >
                                                <td v-if="column.Status && column.code == 'general_012'" :code="column.code">
                                                    <span v-html="ema_id(item.links)"></span>
                                                </td>
                                                <td v-if="column.Status && column.code == 'general_014'" :code="column.code">
                                                    <template v-if="sub_key === 0">{{item.drug_id}}</template>
                                                </td>
                                                <td v-if="column.Status && column.code == 'general_001'" :code="column.code">
                                                    <span v-if="sub_key===0" v-html="check_empty(array(item.indexation))"></span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'general_002'" :code="column.code">
                                                    <span v-html="column_status(item)" v-if="sub_key===0"></span>

                                                </td>
                                                <td v-else-if="column.Status && column.code == 'general_003'" :code="column.code">
                                                    <template v-if="sub_key===0">{{check_empty(item.dci)}}</template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'general_004'" :code="column.code">
                                                    <template v-if="sub_key===0">{{check_empty(item.name)}}</template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'general_005'" :code="column.code"> 
                                                    <span v-if="sub_key===0" v-html="check_empty(array(item.atc))"></span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'general_006'" :code="column.code">
                                                    <template v-if="sub_key===0">{{check_empty(item.firm)}}</template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'general_007'" :code="column.code">
                                                    <template v-if="sub_key===0"> {{check_empty(DDMMMYY(item.ma_date))}} </template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'general_008'" :code="column.code">
                                                    <template v-if="sub_key===0"> {{ check_empty_with_locale(item.atu_france.motif, 'atu_motif.') }} </template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'general_009'" :code="column.code">
                                                    <Readmore v-if="sub_key===0" :longText="$i18n.locale == 'fr' ? item.atu_france['avis_commentaire_fr'] : item.atu_france['avis_commentaire_en']"/>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'general_010'" :code="column.code">
                                                    <Readmore v-if="sub_key===0" :longText="$i18n.locale === 'fr' ? item.atu_france.indication_atu_fr : item.atu_france.indication_atu_en" />
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'general_011'" :code="column.code">
                                                    <span v-if="$i18n.locale === 'fr'" v-html="check_empty(array(item.classification_fr))"></span>
                                                    <span v-else v-html="check_empty(array(item.classification_en))"></span>
                                                </td>
                                                <td v-if="column.Status && column.code == 'general_013'" :code="column.code">
                                                    <span v-html="trs_id(item.links)"></span>
                                                </td>
                                                <td v-if="column.Status && column.code == 'general_015'" :code="column.code">
                                                    {{check_empty(item.atu_france.tc_has_id)}}
                                                </td>

                                                <td v-else-if="column.Status && column.code == 'atu_002'" :code="column.code">
                                                    <template v-if="sub_key===0"> {{ check_empty(DDMMMYY(item.atu_france.date_start)) }} </template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'atu_003'" :code="column.code">
                                                    <template v-if="sub_key===0"> {{ check_empty(DDMMMYY(item.atu_france.date_atu_start))}} </template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'atu_004'" :code="column.code">
                                                    <template v-if="sub_key===0"> {{ check_empty(DDMMMYY(item.atu_france.date_end)) }} </template>
                                                </td>

                                                <td v-else-if="column.Status && column.code == 'aap_has_006'" :code="column.code">
                                                    <template v-if="sub_key===0"> {{ check_empty_with_locale(item.atu_france.type_aap) }} </template>
                                                </td>
                                                
                                                <td v-else-if="column.Status && column.code == 'aap_has_003'" :code="column.code">
                                                    <template v-if="sub_key===0"> {{ check_empty(DDMMMYY(item.atu_france.date_depot_ct))}} </template>
                                                </td>
                                                
                                                <td v-else-if="column.Status && column.code == 'aap_has_005'" :code="column.code">
                                                    <template v-if="sub_key===0"> {{ check_empty(DDMMMYY(item.atu_france.date_avis_ct))}} </template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'aap_has_007'" :code="column.code">
                                                    <template v-if="sub_key===0"> 
                                                        <template v-if="item.atu_france.maladie_rare === 1 || item.atu_france.maladie_grave === 1 || item.atu_france.maladie_invalidante === 1">{{$t('yes')}}</template>
                                                        <template v-else-if="item.atu_france.maladie_rare === null || item.atu_france.maladie_grave === null || item.atu_france.maladie_invalidante === null">-</template>
                                                        <template v-else>{{$t('no')}}</template>
                                                    </template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'aap_has_008'" :code="column.code">
                                                    <template v-if="sub_key===0"> {{ check_empty_with_locale(item.atu_france.existence_traitements_appropries, 'evaluation_economic.yes_no_')}} </template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'aap_has_010'" :code="column.code">
                                                    <template v-if="sub_key===0"> {{ check_empty_with_locale(item.atu_france.peut_pas_etre_differee, 'evaluation_economic.yes_no_')}} </template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'aap_has_009'" :code="column.code">
                                                    <template v-if="sub_key===0"> {{ check_empty_with_locale(item.atu_france.check_efficacite_et_securite, 'evaluation_economic.yes_no_')}} </template>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'aap_has_011'" :code="column.code">
                                                    <template v-if="sub_key===0"> 
                                                        <template v-if="item.atu_france.criteres_presumant_le_caractere_innovant_1 === 1 && item.atu_france.criteres_presumant_le_caractere_innovant_2 === 1 && item.atu_france.criteres_presumant_le_caractere_innovant_3 === 1"> {{$t('yes')}} </template>
                                                        <template v-else-if="item.atu_france.criteres_presumant_le_caractere_innovant_1 === null || item.atu_france.criteres_presumant_le_caractere_innovant_2 === null || item.atu_france.criteres_presumant_le_caractere_innovant_3 === null">-</template>
                                                        <template v-else> {{$t('no')}} </template>
                                                    </template>
                                                </td>
                                                <template v-else-if="column.Status && column.code == 'aap_has_012'" :code="column.code">
                                                    <td :code="'aap_has_0121'">
                                                        <span v-if="sub_key === 0" v-html="hta_process_status_2(item)"></span>
                                                    </td>
                                                    <td :code="'aap_has_0122'">
                                                        <span v-if="sub_key === 0" v-html="rembursement_status_2(item)"></span>
                                                    </td>
                                                </template>
                                                <template v-else-if="column.Status && column.code == 'aap_has_013'" :code="column.code">
                                                    <td :code="'aap_has_0131'">
                                                        <span v-if="item.link_transparence_drug_center && item.link_transparence_drug_center[sub_key]" v-html="hta_process_status_2(item.link_transparence_drug_center[sub_key])"></span>
                                                        <span v-else>-</span>
                                                    </td>
                                                    <td :code="'aap_has_0132'">
                                                        <span v-if="item.link_transparence_drug_center && item.link_transparence_drug_center[sub_key]" v-html="rembursement_status_2(item.link_transparence_drug_center[sub_key])"></span>
                                                        <span v-else>-</span>
                                                    </td>
                                                </template>

                                            </template>

                                        </tr>
                                        </template>
                                    </tbody>

                                </table>
                            </div>
                            <!---- Area Table Result End----->
                             
                            
                        </div>
                    </div> 

                    <div v-else class="body-w100" >
                        <div class="box-detail">
                            <div class="wrapper-box-hta">
                                <h1 class="fa-pull-left mr-4 mt-1">{{$t('No Data')}}</h1>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                    </div> 

                    <TablePrix v-if="$route.query.tab == 'prix'" main_page="AtuPostAtu" />

                    <TableEssaisClinique v-if="$route.query.tab == 'essais_clinique'" main_page="AtuPostAtu" />
 
                </div>
            </div>
        </div>


        <Loader :isLoad="isLoad" />
        <Footer />
    </div>
    
</template> 

<script>
import Header from '@/components/Layout/Header.vue'; 
import Footer from '@/components/Layout/Footer.vue'; 
import IconTopTable from '@/components/elements/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import Readmore from '@/components/elements/readmore.vue';
import ColumnThead from '@/components/elements/column_thead.vue'; 
import ConfigColumnAdvance from '@/components/advanced_result/modal_config_column_advance.vue'; 
import ModalSaveKeyword from '@/components/elements/modal_save_keyword.vue';
import TablePrix from '@/components/advanced_result/table_prix.vue';
import TableEssaisClinique from '@/components/advanced_result/table_essais_clinique.vue';
import { currentDate, isAdmin, isUserFrench, column_status, eventHandlerResize, DDMMMYY, modifyPage, 
        array, check_empty, check_empty_with_locale, odd_even_border, ema_id, trs_id, hta_process_status_2, rembursement_status_2 } from '@/utils';
import Loader from "@/components/Layout/Loader";
// Initialize exporting module.

export default {
    name: 'advanced_result_atu_postatu',
    components: {        
        Header,
        Footer,   
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        ConfigColumnAdvance,
        ModalSaveKeyword,
        TablePrix,
        TableEssaisClinique,
        Readmore,
        Loader
    },
    data() {
        return {             
            isFilter : false,
            page : "atu_postatu"      
        }
    },
    mounted() {
        //search 
        this.request_data({ mounted : true, order_by:'decision_date_num', sort:'DESC' })
        //this.$store.dispatch(`advanced_result/result_atu_postatu_chart`, {...this.$route.params, chart:true})  
        //this.chart_atu_avis()
        document.body.classList.add('body-bg-gray')   
        if(this.$route.query.zoom){
            this.eventHandlerResize()
        }
    },
    methods: {
        request_data(param = {}){
            let paramObj = {...this.$route.params}
            paramObj['base64Search'] = paramObj['base64Search'].replace('/\-/g', '+').replace('/_/g', '/') // eslint-disable-line
            if(!param['mount'] && this.sort_column && Object.keys(this.data).includes(this.sort_column['order_by'])){
                paramObj['order_by'] = this.sort_column['order_by']
            }
            else if(param['order_by']){
                paramObj['order_by'] = param['order_by']
            }
            else{
                paramObj['order_by'] = 'decision_date_num'
            }
            paramObj['sort'] = !param['mounted'] && this.sort_column && this.sort_column['sort'] ? this.sort_column['sort'] : param['sort']

            console.log(this.sort_column)
            if(param.page){
                paramObj['page'] = param.page
            }
            if(this.select_export_obj.isSelect){
                paramObj['select_export'] = this.select_export_obj.listID
            }
            if(this.data_filter !== ""){
                paramObj['filter'] = this.data_filter
            }
            if(this.$route.query.keyword_id){
                paramObj['keyword_id'] = this.$route.query.keyword_id
            }
            if(param.export){
                delete param.export;
                this.$store.dispatch(`advanced_result/result_atu_postatu_excel`, [paramObj, currentDate()+"_advanced_atu_postatu" ] ) 
            }else{
                this.$store.dispatch(`advanced_result/result_atu_postatu`, paramObj ) 
            }
            
            if(!param.mounted){
                this.selectTab('expert')
            }
        }, 
        selectTab(tab){
            let query = Object.assign({}, this.$route.query);
            if(query.tab !== tab){
                query.tab = tab            
                console.log(this.$route.query)
                this.$router.push({ query });  
            }                       
        },
        handleClickShowFilter(){
            this.$store.dispatch("advanced_result/set_show_filter", true)
        },
        selectExport(){ console.log('tom selectExport')
            this.$store.dispatch("advanced_result/select_export", { key : "isSelect" , value : true})                 
            this.request_data() 
        },
        clearExport(){
            this.$store.dispatch("advanced_result/select_export", "clear")                      
            this.request_data()
        },
        chart_atu_avis() {
            var options = {
                chart: {
                    type: 'pie',
                    renderTo: 'chart_atu_avis',
                    margin: [0, 0, 0, 0],
                    backgroundColor: null,
                    style: {
                        fontSize: '13px',
                        color: '#7d8693'
                    },
                    plotShadow: false,
                },
                title: { text: null },
                credits: { enabled: false },
                exporting: { enabled: false },
                legend: { enabled: false },
                tooltip: {
                    formatter: function () {
                        return this.key + ' : <strong>' + this.y + '</strong>';
                    }
                },
                plotOptions: {
                    pie: {
                        //size: '85%',
                        innerSize: '75%',
                        data: [], //put data
                        dataLabels: {
                            enabled: true,
                            format: '{point.name} : {point.y}'
                        }
                    },
                    series: {
                        cursor: 'pointer',                         
                    }
                },
                series: [
                    {
                        name: 'labels name',
                        dataLabels: {
                            color: '#7d8693',
                            // connectorWidth: 0,
                            // distance: 3,
                            // style: {
                            //     fontSize: '11px',
                            //     textOutline: 0,
                            // }
                        },
                    },
                    // {
                    //     name: 'labels y',
                    //     dataLabels: {
                    //         color: '#fff',
                    //         connectorWidth: 0,
                    //         distance: - 15,
                    //         formatter: function () {
                    //             return this.y;
                    //         },
                    //         style: {
                    //             fontSize: '11px',
                    //             textOutline: 0,
                    //         }
                    //     },
                    // }
                ]
            }

            //var txt_json = [{ "name": "Published", "y": this.test, "color": "#47ad6d", "filter": "Published" }];
            //        console.log(txt_json);
            if(this.data_chart_atu.length){
                options.plotOptions.pie.data = this.data_chart_atu;
            }
        },
        handleClickBoxSearch(){
            this.$store.dispatch("header/setOpenBoxSearch", !this.openBoxSearch)
        }, 
        column_status,
        eventHandlerResize,
        modifyPage,
        DDMMMYY,
        check_empty_with_locale,
        array,
        check_empty,
        odd_even_border,
        ema_id, 
        trs_id,
        hta_process_status_2, 
        rembursement_status_2,
        max_row(param){
            const temp = []
            temp.push(1)       
            if(param.atu_france.post_atu){
                temp.push(param.atu_france.post_atu.length)
            }
            return Math.max(...temp)
        }
    },
    computed: {
        isAdmin,
        isUserFrench,
        user(){
            return this.$store.getters['auth/user']
        },
        type(){
            return this.$store.getters['advanced_result/type'] 
        },
        data(){
            return this.$store.getters['advanced_result/data'];
        },
        data_chart_atu(){
            return this.$store.getters['advanced_result/data_chart'];
        },        
        orderColumn(){
            const columns = this.$store.getters['advanced_result/column']; 
            let tempColumns = []
            for (const item of columns) {
                tempColumns = [...tempColumns, ...item.column]
            } 
            return tempColumns
        },
        default_column(){
            const columns = this.$store.getters['advanced_result/default_column']; 
            let tempColumns = []
            for (const item of columns) {
                tempColumns = [...tempColumns, ...item.column]
            } 
            return tempColumns                       
        },
        column(){
            return this.$store.getters['advanced_result/column']                       
        },
        reset_column(){
            return this.$store.getters['advanced_result/reset_column']                       
        },
        openBoxSearch () {  
            return this.$store.getters['header/openBoxSearch']
        },                 
        count() {
            return this.$store.getters['advanced_result/count']
        },
        count_filter() {
            return this.$store.getters['advanced_result/count_filter']
        },
        isLoad() {  
            if(this.$store.getters['advanced_result/load'] || this.$store.getters['advanced_result/load_chart'] || this.$store.getters['advanced_result/prix_load'] || this.$store.getters['essais_clinique/load']){
                return true
            }
            return false
        },
        select_export: {
            set(val){ this.$store.dispatch("advanced_result/select_export", {key: 'listID', value: val}) },
            get(){ 
                if(this.$store.getters['advanced_result/select_export'].checkAll){
                    return true
                }else{
                    return this.$store.getters['advanced_result/select_export'] ? this.$store.getters['advanced_result/select_export'].listID : null
                }
            }
        },
        select_export_obj(){
            return this.$store.getters['advanced_result/select_export']
        },
        data_filter(){
            return this.$store.getters['advanced_result/data_filter']
        },
        sort_column(){
            return this.$store.getters['advanced_result/sort_column']
        }
    },
    watch:{
        data_chart_atu: function () {           
            this.chart_atu_avis()    
        }
    },
    created() {        
        if(this.$route.query.zoom){
            window.addEventListener("resize", this.eventHandlerResize);
        }
    },
    unmounted () {
        document.body.classList.remove('body-bg-gray'); 
        this.$store.dispatch("advanced_result/clearState") 
        this.$store.dispatch("essais_clinique/clearState")
        if(this.$route.query.zoom){
            window.removeEventListener("resize", this.eventHandlerResize);
        }
    }
}
</script>

<style scoped>
.main-body{
    min-height: 654px;
}
.box-fix-left{ padding-top:133px}
/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 60vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>